<!-- eslint-disable vue/no-use-v-if-with-v-for -->
<template>
  <div class="settings-creation-recursive-block" @click="$emit('edit', feature)">
    <v-fade-transition leave-absolute>
      <v-card
        class="settings-creation-recursive-block__card"
        :class="{
          'settings-creation-recursive-block__card--hover': isDragHover && !isActive,
          'settings-creation-recursive-block__card--active': isActive,
          'settings-creation-recursive-block__card--grab': isParent,
        }"
        flat
        :key="`feature ${feature.identifier}`"
      >
        <div class="tw-flex tw-flex-row tw-items-center tw-gap-4" :key="'jsonField.key'">
          <v-icon>{{ icons.mdiDotsSquare }}</v-icon>
          <div :class="{ 'tw-flex tw-flex-row tw-items-center tw-gap-4': isOneRow }">
            <UiLabel
              id="name"
              :class="{ 'settings-creation-recursive-block__card--grab': isParent }"
              :label="feature.name"
            />
            <UiSubtitle :subtitle="feature.slug" />
          </div>
          <v-btn class="tw-ml-auto" small icon @click.stop="$emit('add', feature.identifier)">
            <v-icon>
              {{ icons.mdiFilePlus }}
            </v-icon>
          </v-btn>
        </div>
      </v-card>
    </v-fade-transition>

    <template v-if="displayChild">
      <settings-creation-recursive-block
        v-for="child in children"
        :key="`child ${child.identifier}`"
        :feature="child"
        :subtitle="child"
        :isOneRow="false"
        :children="child.childFeatures"
        @edit="object => $emit('edit', object)"
        @add="identifier => $emit('add', identifier)"
      />
    </template>
  </div>
</template>

<script>
import { mdiDotsSquare, mdiFilePlus } from '@mdi/js'
import SettingsCreationRecursiveBlock from '@/components/Settings/Creation/RecursiveBlock.vue'
import UiLabel from '@/components/UI/Label.vue'
import UiSubtitle from '@/components/UI/Subtitle.vue'

export default {
  name: 'SettingsCreationRecursiveBlock',
  props: {
    feature: {
      type: Object,
      required: true,
    },
    isOneRow: {
      type: Boolean,
      required: false,
      default: true,
    },
    isParent: {
      type: Boolean,
      required: false,
      default: false,
    },
    displayChild: {
      type: Boolean,
      required: false,
      default: true,
    },
    isActive: {
      type: Boolean,
      required: false,
      default: false,
    },
    isDragHover: {
      type: Boolean,
      required: false,
      default: false,
    },
    children: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  components: {
    SettingsCreationRecursiveBlock,
    UiLabel,
    UiSubtitle,
  },
  data() {
    return {
      icons: {
        mdiDotsSquare,
        mdiFilePlus,
      },
    }
  },
  methods: {
    startDrag(evt, id) {
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData('itemID', id)
    },
    editFeature(feature) {
      this.$emit('edit', feature)
    },
  },
}
</script>

<style lang="scss" scoped>
.settings-creation-recursive-block {
  @apply tw-ml-12;

  &__card {
    @apply tw-my-2 tw-p-4;
    cursor: pointer;
    transition: all 150ms ease-out;

    &:hover {
      box-shadow: 0px 0px 4px 1px #d7d7d7 !important;
    }

    &--grab {
      cursor: grab;
    }

    &--hover {
      background-color: #e2f1ff;
    }
    &--active {
      @apply tw-ml-24;
      opacity: 0.2;
    }
  }
}
</style>
