<template>
  <v-fade-transition leave-absolute>
    <div class="tw-h-full">
      <v-overlay :value="updating" v-if="updating" absolute>
        <v-progress-circular indeterminate size="64" width="6"></v-progress-circular>
      </v-overlay>
      <div class="settings-creation-menus">
        <UiContainer large with-actions>
          <diV class="tw-flex">
            <UiTitle :title="$t('settings.creation.menus.title')" />
            <v-spacer />
            <v-btn @click="createNew" outlined color="#449afd">
              <v-icon class="tw-mr-2">{{ icons.mdiPlus }}</v-icon>
              {{ $t('settings.creation.menus.create') }}
            </v-btn>
          </diV>

          <div class="tw-mt-4 tw-flex tw-flex-col tw-gap-1">
            <v-treeview
              class="settings-creation-menus__treeview"
              :active.sync="active"
              v-model="selectedMenus"
              :items="newMenus"
              item-text="label"
              item-children="childMenus"
              item-key="identifier"
              selection-type="leaf"
              hoverable
              transition
              return-object
            >
              <template v-slot:label="{ item }">
                <draggable
                  :list="newMenus"
                  ghost-class="ghost"
                  class="settings-creation-menus__treeview__item"
                  :id="item.identifier"
                  :data-parent="item.parentMenu"
                  @start="checkStart"
                  @end="checkEnd"
                  ><span :id="item.identifier">{{ item.label }}</span>
                  <v-spacer />
                  <v-btn icon @click="openEditModal(item)"
                    ><v-icon>{{ icons.mdiPencil }}</v-icon></v-btn
                  >
                </draggable></template
              ></v-treeview
            >
          </div>
        </UiContainer>
        <settings-creation-menu-modal
          :roles="roles"
          :menu="selectedMenu"
          :modules="newModules"
          :parents="newMenus"
          :features="newFeatures"
          :mode="mode"
          :updating="updating"
          v-if="openModal === true"
          @close="openModal = false"
          @save="save"
          @update="update"
          @delete="deleteEmit"
        />
      </div>
    </div>
  </v-fade-transition>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { mdiPencil, mdiPlus } from '@mdi/js'
import draggable from 'vuedraggable'
import { clone } from '@/utils/utilities.util'
import UiContainer from '@/components/UI/Container.vue'
import UiTitle from '@/components/UI/Title.vue'
import SettingsCreationMenuModal from '@/components/Settings/Creation/MenuModal.vue'
import { stringToSlugUnderscore } from '@/utils/utilities.util'

export default {
  name: 'SettingsCreationMenus',

  components: {
    SettingsCreationMenuModal,
    UiContainer,
    UiTitle,
    draggable,
  },
  data() {
    return {
      updating: false,
      selectedMenus: [],
      openModal: false,
      newMenus: [],
      newModules: [],
      active: [],
      selectedMenu: {},
      draggedMenu: null,
      mode: null,
      icons: {
        mdiPencil,
        mdiPlus,
      },
    }
  },
  async created() {
    this.updating = true
    await this.getRoles()
    await this.getMenusCore()
    await this.getModules()
    this.newMenus = this.orderMenus(clone(this.menus))
    this.newModules = clone(this.modules)
    this.newFeatures = clone(this.features)
    this.newMenus = this.orderMenus(this.newMenus)
    this.updating = false
  },
  computed: {
    ...mapState({
      roles: state => state.users.roles,
      menus: state => state.setting.menus,
      modules: state => state.backoffice.modules,
      features: state => state.setting.features,
    }),
  },
  methods: {
    ...mapActions({
      getRoles: 'users/getRoles',
      getMenusCore: 'setting/getMenusCore',
      getModules: 'backoffice/getModules',
      patchMenu: 'setting/patchMenu',
      postMenu: 'setting/postMenu',
      deleteMenu: 'setting/deleteMenu',
    }),
    addPositionToMenu(menus) {
      const positionedMenus = menus.map((menu, index) => {
        if (menu.childMenus.length > 0) {
          menu.childMenus = this.addPositionToMenu(menu.childMenus)
        }
        return {
          ...menu,
          position: index,
        }
      })
      return positionedMenus
    },
    orderMenus(menus) {
      const positionedMenus = menus.sort((first, second) => {
        if (first.childMenus.length > 0) {
          first.childMenus = this.orderMenus(first.childMenus)
        }
        return first.position - second.position
      })
      return positionedMenus
    },
    findTargetIndex(menus, identifier) {
      if (!menus) {
        return
      }
      for (var i = 0; i < menus.length; i++) {
        var menu = menus[i]

        if (menu.identifier === identifier) {
          return menu.position
        }
        const child = this.findTargetIndex(menu.childMenus, identifier)
        if (child) {
          return child.position
        }
      }
    },
    findTreeItem(items, identifier) {
      if (!items) {
        return
      }
      for (var i = 0; i < items.length; i++) {
        var item = items[i]
        if (item.identifier === identifier) {
          return item
        }
        const child = this.findTreeItem(item.childMenus, identifier)
        if (child) {
          return child
        }
      }
    },
    checkStart(evt) {
      this.active = []
      this.active.push(this.findTreeItem(this.newMenus, evt.from.id))
    },
    async checkEnd(evt) {
      var itemSelected = this.active[0]

      if (!itemSelected.parentMenu) {
        itemSelected.parentMenu = null
        this.newMenus.splice(this.newMenus.indexOf(this.active[0]), 1)
        for (var i = 0; i < this.newMenus.length; i++) {
          this.newMenus[i] = {
            ...this.newMenus[i],
            position: i,
          }
        }
        this.newMenus.splice(this.findTargetIndex(this.newMenus, evt.originalEvent.target.id), 0, this.active[0])
      } else {
        const targetParentMenu = this.findTreeItem(this.newMenus, this.active[0].parentMenu)
        targetParentMenu.childMenus.splice(targetParentMenu.childMenus.indexOf(this.active[0]), 1)
        for (var j = 0; j < targetParentMenu.childMenus.length; j++) {
          targetParentMenu.childMenus[j] = {
            ...targetParentMenu.childMenus[j],
            position: j,
          }
        }
        targetParentMenu.childMenus.splice(
          this.findTargetIndex(targetParentMenu.childMenus, evt.originalEvent.target.id),
          0,
          this.active[0]
        )
      }
      const patchPromises = this.newMenus.map((newMenu, index) => this.patchMenuPosition(index, newMenu))

      this.updating = true
      await Promise.all(patchPromises)
      this.active = []
      this.updating = false
      return false
    },
    async patchMenuPosition(index, menu) {
      const childIRIList = menu.childMenus.map(childMenu => childMenu.identifier)
      const simplifiedMenu = {
        ...menu,
        childMenus: childIRIList,
        position: index,
      }
      await this.patchMenu({ menuId: menu.slug, menu: simplifiedMenu })
    },
    openEditModal(menu) {
      this.selectedMenu = menu
      this.openModal = true
      this.mode = 'edit'
    },
    createNew() {
      this.selectedMenu = {
        roles: [],
        features: [],
      }
      this.openModal = true
      this.mode = 'create'
    },
    async save(menu) {
      this.updating = true
      const simplifiedMenu = {
        ...menu,
        position: this.menus.length + 1,
        status: true,
        slug: stringToSlugUnderscore(menu.label),
      }
      await this.postMenu(simplifiedMenu)
      await this.getMenusCore()
      this.newMenus = clone(this.menus)
      this.updating = false
      this.openModal = false
    },
    async update(menu) {
      this.updating = true
      const childIRIList = menu.childMenus.map(childMenu => childMenu.identifier)
      const simplifiedMenu = {
        ...menu,
        childMenus: childIRIList,
        parentMenu: menu.parentMenu,
        slug: stringToSlugUnderscore(menu.label),
      }
      await this.patchMenu({ menuId: menu.slug, menu: simplifiedMenu })
      await this.getMenusCore()
      this.newMenus = clone(this.menus)
      this.updating = false
      this.openModal = false
    },
    async deleteEmit(menu) {
      this.updating = true
      await this.deleteMenu(menu.slug)
      await this.getMenusCore()
      this.newMenus = clone(this.menus)
      this.updating = false
      this.openModal = false
    },
  },
}
</script>

<style lang="scss" scoped>
.settings-creation-menus {
  &__treeview {
    &__item {
      @apply tw-flex tw-items-center;
      cursor: all-scroll;
    }
  }
  .ghost {
    opacity: 0.2;
  }
}
</style>
