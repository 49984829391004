<template>
  <ui-modal
    class="ui-modal-feature"
    width="600"
    :title="$t('modal.settings.creation.title')"
    :dialog="true"
    @close="$emit('close')"
  >
    <template v-slot:body>
      <div>
        <ui-block id="labelLabel" class="required-asterisk" :label="$t('modal.settings.creation.name.label')">
          <template v-slot:body>
            <v-text-field
              v-model="modelData.name"
              id="name"
              solo
              flat
              outlined
              dense
              :placeholder="$t('modal.settings.creation.name.placeholder')"
              :error-messages="simpleErrors('modelData', 'name')"
            />
          </template>
        </ui-block>
        <ui-block id="slugLabel" class="required-asterisk" :label="$t('modal.settings.creation.slug.label')">
          <template v-slot:body>
            <v-text-field
              v-model="modelData.slug"
              id="slug"
              solo
              flat
              outlined
              dense
              :placeholder="$t('modal.settings.creation.slug.placeholder')"
              :error-messages="simpleErrors('modelData', 'slug') && slugErrors()"
            />
          </template>
        </ui-block>
      </div>
    </template>
    <template v-slot:actions>
      <v-btn rounded plain @click="$emit('close')">
        {{ $t('button.cancel') }}
      </v-btn>
      <v-tooltip v-if="config.mode === 'edition'" top>
        <template v-slot:activator="{ on, attrs }">
          <v-btn rounded fab small color="error" v-bind="attrs" v-on="on" @click="$emit('delete', modelData)">
            <v-icon> {{ icons.mdiTrashCanOutline }} </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('button.delete') }}</span>
      </v-tooltip>
      <v-btn rounded color="primary" @click="save">
        {{ $t(`button.${config.mode === 'create' ? 'create' : 'save'}`) }}
      </v-btn>
    </template>
  </ui-modal>
</template>

<script>
import { validationMixin } from 'vuelidate'
import ErrorsMixin from '@/mixins/errors.mixin'
import { required } from 'vuelidate/lib/validators'
import { mdiTrashCanOutline } from '@mdi/js'
import { stringToSlug } from '@/utils/utilities.util'

import UiModal from '@/components/UI/Modal.vue'
import UiBlock from '@/components/UI/Block.vue'

const initDefaultData = () => ({
  name: '',
  slug: '',
})

const initFeatureData = () => ({
  parentFeature: '',
})

export default {
  name: 'SettingsCreationModal',
  mixins: [validationMixin, ErrorsMixin],
  components: {
    UiModal,
    UiBlock,
  },
  props: {
    config: {
      type: Object,
      required: false,
      default: () => ({
        mode: 'create',
        type: 'default',
      }),
    },
  },
  data() {
    return {
      icons: {
        mdiTrashCanOutline,
      },
      modelData: {
        ...initDefaultData(),
        ...(this.config.type === 'feature' && initFeatureData()),
      },
    }
  },
  watch: {
    'modelData.name'() {
      if (this.config.mode === 'create') {
        this.modelData.slug = stringToSlug(this.modelData.name)
      }
    },
  },
  created() {
    this.modelData = {
      ...this.modelData,
      ...this.config.data,
    }
  },
  methods: {
    slugErrors() {
      const errors = []
      if (!this.$v.modelData.slug.$dirty) return errors
      !this.$v.modelData.slug.required && errors.push(this.$t('error.required'))
      !this.$v.modelData.slug.formatSlug && errors.push(this.$t('error.slug'))
      return errors
    },
    save() {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$emit(this.config.mode === 'create' ? 'save' : 'update', this.modelData)
      }
    },
    checkIfValidSlug(slug) {
      const regexExp = /^[a-z](-?[a-z])*$/
      return regexExp.test(slug)
    },
  },
  validations() {
    return {
      modelData: {
        name: {
          required,
        },
        slug: {
          required,
          formatSlug: slug => this.checkIfValidSlug(slug),
        },
      },
    }
  },
}
</script>
