<template>
  <div class="settings-creation-type">
    <UiContainer large with-actions>
      <UiTitle :title="$t('settings.creation.type.title')" />
      <div class="tw-mt-4 tw-flex tw-flex-col tw-gap-1">
        <v-slide-y-reverse-transition group>
          <settings-creation-block
            v-for="(type, index) in types"
            :key="`type ${index}`"
            :label="type.name"
            :subtitle="type.slug"
            :isOneRow="false"
            @click.native="openModalEdit(type)"
          />
        </v-slide-y-reverse-transition>
        <div class="tw-text-center">
          <v-btn @click="createNew" class="tw-mt-6" outlined color="#449afd">
            <v-icon class="tw-mr-2">{{ icons.mdiPlus }}</v-icon>
            {{ $t('settings.creation.type.create') }}
          </v-btn>
        </div>
      </div>
    </UiContainer>
    <settings-creation-modal
      :config="configModal"
      v-if="openModal === true"
      @close="openModal = false"
      @save="save"
      @update="update"
      @delete="deleteEmit"
    />
  </div>
</template>

<script>
import { mdiPlus } from '@mdi/js'
import UiContainer from '@/components/UI/Container.vue'
import UiTitle from '@/components/UI/Title.vue'
import SettingsCreationBlock from '@/components/Settings/Creation/Block.vue'
import SettingsCreationModal from '@/components/Settings/Creation/Modal.vue'

const initDefaultModal = () => ({
  mode: 'create',
  type: 'default',
  data: null,
})

export default {
  name: 'SettingsCreationType',
  props: {
    types: {
      type: Array,
      required: true,
    },
  },
  components: {
    UiContainer,
    UiTitle,
    SettingsCreationBlock,
    SettingsCreationModal,
  },
  data() {
    return {
      icons: {
        mdiPlus,
      },
      openModal: false,
      configModal: {
        ...initDefaultModal(),
      },
    }
  },
  methods: {
    createNew() {
      this.configModal = initDefaultModal()
      this.openModal = true
    },
    openModalEdit(type) {
      this.configModal = {
        mode: 'edition',
        type: 'default',
        data: type,
      }
      this.openModal = true
    },
    save(type) {
      this.$emit('save', type)
      this.openModal = false
    },
    update(type) {
      this.$emit('update', type)
      this.openModal = false
    },
    deleteEmit(type) {
      this.$emit('delete', type)
      this.openModal = false
    },
  },
}
</script>

<style lang="scss" scoped>
.settings-creation-type {
  @apply tw-h-full;
}
</style>
