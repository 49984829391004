<template>
  <v-card class="tw-my-2 tw-p-4 tw-shadow">
    <div :key="'jsonField.key'" class="tw-cursor-pointer tw-flex tw-flex-row tw-items-center tw-gap-4">
      <v-icon>{{ icons.mdiDotsSquare }}</v-icon>
      <div :class="{ 'tw-flex tw-flex-row tw-items-center tw-gap-4': isOneRow }">
        <UiLabel class="tw-cursor-pointer" id="name" :label="label" />
        <UiSubtitle :subtitle="subtitle" />
      </div>
    </div>
  </v-card>
</template>

<script>
import { mdiDotsSquare } from '@mdi/js'
import UiLabel from '@/components/UI/Label.vue'
import UiSubtitle from '@/components/UI/Subtitle.vue'

export default {
  props: {
    label: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    isOneRow: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  components: {
    UiLabel,
    UiSubtitle,
  },
  data() {
    return {
      icons: {
        mdiDotsSquare,
      },
    }
  },
}
</script>
