<template>
  <ui-modal
    class="ui-menu-modal"
    width="600"
    :title="$t(`modal.settings.menu.title.${mode}`)"
    :dialog="true"
    @close="$emit('close')"
  >
    <template v-slot:body>
      <div>
        <!-- PARENT INPUT -->
        <ui-block
          v-if="mode === 'create'"
          id="parentLabel"
          :class="{ 'required-asterisk': !isRootMenu && mode === 'create' }"
          :label="$t('modal.settings.menu.parent.label')"
        >
          <template v-slot:body>
            <div class="tw-flex tw-items-center">
              <v-checkbox
                class="tw-mr-6 tw-mt-2 tw-pt-0"
                v-model="isRootMenu"
                :label="$t('modal.settings.menu.parent.rootMenu')"
              ></v-checkbox>
              <v-select
                v-if="!isRootMenu"
                class="tw-mt-0 tw-pt-0"
                :items="parents"
                item-text="label"
                item-value="identifier"
                v-model="selectedParent"
                :placeholder="$t('modal.settings.menu.parent.placeholder')"
                :error-messages="fieldErrors('selectedParent')"
              ></v-select>
            </div>
          </template>
        </ui-block>

        <!-- LABEL INPUT -->
        <ui-block class="required-asterisk tw-mt-2" id="labelLabel" :label="$t('modal.settings.menu.label.label')">
          <template v-slot:body>
            <v-text-field
              v-model="newLabel"
              id="label"
              solo
              flat
              outlined
              dense
              :placeholder="$t('modal.settings.menu.label.placeholder')"
              :error-messages="fieldErrors('newLabel')"
            />
          </template>
        </ui-block>

        <!-- MODULE INPUT -->
        <ui-block
          v-if="isRootMenu"
          :class="{ 'required-asterisk': isRootMenu && mode === 'create' }"
          class="tw-mt-2"
          id="moduleLabel"
          :label="$t('modal.settings.menu.module.label')"
        >
          <template v-slot:body>
            <v-select
              :items="modules"
              item-text="module"
              item-value="module"
              v-model="selectedModule"
              :placeholder="$t('modal.settings.menu.module.placeholder')"
              :error-messages="fieldErrors('selectedModule')"
            ></v-select>
          </template>
        </ui-block>

        <!-- ROLES INPUT -->
        <ui-block class="required-asterisk tw-mt-2" id="roleLabel" :label="$t('modal.settings.menu.role.label')">
          <template v-slot:body>
            <div class="tw-grid tw-grid-cols-2 tw-mt-1">
              <v-checkbox
                class="tw-mt-0"
                v-for="(role, index) in roles"
                v-model="selectedRoles"
                :key="role.name"
                multiple
                :hide-details="index !== roles.length - 1"
                :label="role.value"
                :value="role.name"
                :error-messages="fieldErrors('selectedRoles')"
              ></v-checkbox>
            </div>
          </template>
        </ui-block>

        <!-- FEATURES INPUT -->
        <ui-block class="tw-mt-2" id="featureLabel" :label="$t('modal.settings.menu.feature.label')">
          <template v-slot:body>
            <div class="tw-grid tw-grid-cols-2 tw-mt-1">
              <v-checkbox
                class="tw-mt-0"
                v-for="feature in features"
                v-model="selectedFeatures"
                :key="feature.identifier"
                :label="feature.name"
                :value="feature.identifier"
                hide-details
                multiple
              ></v-checkbox>
            </div>
          </template>
        </ui-block>

        <!-- ICON INPUT -->
        <ui-block class="tw-mt-2" id="iconLabel" :label="$t('modal.settings.menu.icon.label')">
          <template v-slot:body>
            <div class="tw-flex">
              <v-text-field
                v-model="newIcon"
                id="icon"
                solo
                flat
                outlined
                dense
                :placeholder="$t('modal.settings.menu.icon.placeholder')"
              />
            </div>
          </template>
        </ui-block>
      </div>
    </template>
    <template v-slot:actions>
      <v-btn rounded plain :loading="updating" :disbaled="updating" @click="$emit('close')">
        {{ $t('button.cancel') }}
      </v-btn>
      <v-tooltip top v-if="mode === 'edit'">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            rounded
            fab
            small
            color="error"
            v-bind="attrs"
            v-on="on"
            :loading="updating"
            :disbaled="updating"
            @click="deleteMenu"
          >
            <v-icon> {{ icons.mdiTrashCanOutline }} </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('button.delete') }}</span>
      </v-tooltip>
      <v-btn rounded color="primary" :disbaled="updating" :loading="updating" @click="save">
        {{ $t('button.save') }}
      </v-btn>
    </template>
  </ui-modal>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, requiredIf } from 'vuelidate/lib/validators'
import { mdiTrashCanOutline, mdiPlus } from '@mdi/js'

import UiModal from '@/components/UI/Modal.vue'
import UiBlock from '@/components/UI/Block.vue'

export default {
  name: 'SettingsCreationModal',
  mixins: [validationMixin],
  components: {
    UiModal,
    UiBlock,
  },
  props: {
    menu: {
      type: Object,
      required: true,
    },
    modules: {
      type: Array,
      required: true,
    },
    roles: {
      type: Array,
      required: true,
    },
    features: {
      type: Array,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
    parents: {
      type: Array,
      required: true,
    },
    updating: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      icons: {
        mdiTrashCanOutline,
        mdiPlus,
      },
      newLabel: null,
      newIcon: null,
      selectedModule: null,
      selectedRoles: [],
      selectedFeatures: [],
      selectedParent: null,
      isRootMenu: false,
      updatedMenu: null,
    }
  },
  created() {
    this.newLabel = this.menu.label
    this.newIcon = this.menu.icon || null
    this.selectedModule = this.menu.module
    this.selectedRoles = this.roles.filter(role => this.menu.roles.includes(role.name)).map(role => role.name)
    this.selectedParent = this.menu.parentMenu
    this.selectedFeatures = this.features
      .filter(feature => this.menu.features.includes(feature.identifier))
      .map(feature => feature.identifier)
  },
  methods: {
    fieldErrors(fieldName) {
      const errors = []
      if (!this.$v[fieldName].$dirty) return errors
      !this.$v[fieldName].required && errors.push(this.$t('error.required'))
      return errors
    },
    save() {
      const saveFeatures = this.features
        .filter(feature => {
          if (this.selectedFeatures.includes(feature.identifier)) {
            return feature.identifier
          }
        })
        .map(saveFeature => saveFeature.identifier)
      const saveRoles = this.roles
        .filter(role => {
          if (this.selectedRoles.includes(role.name)) {
            return role.name
          }
        })
        .map(saveRole => saveRole.name)
      this.updatedMenu = {
        ...this.menu,
        childMenus: this.menu.childMenus || [],
        label: this.newLabel,
        module: this.selectedModule,
        icon: this.newIcon,
        features: saveFeatures,
        roles: saveRoles,
        parentMenu: this.selectedParent,
        position: this.menu.position,
        status: this.menu.status || true,
        url: null,
      }
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.$emit(this.mode === 'create' ? 'save' : 'update', this.updatedMenu)
      }
    },
    deleteMenu() {
      this.updatedMenu = {
        ...this.menu,
      }
      this.$emit('delete', this.updatedMenu)
    },
  },
  validations() {
    return {
      newLabel: {
        required,
      },
      selectedParent: {
        required: requiredIf(() => {
          return !this.isRootMenu && this.mode === 'create'
        }),
      },
      selectedModule: {
        required: requiredIf(() => {
          return this.isRootMenu && this.mode === 'create'
        }),
      },
      selectedRoles: {
        required,
      },
    }
  },
}
</script>
