<template>
  <div class="settings-creation-feature">
    <UiContainer large with-actions>
      <UiTitle :title="$t('settings.creation.feature.title')" />
      <div class="tw-mt-4 tw-flex tw-flex-col tw-gap-1">
        <v-slide-y-reverse-transition group>
          <settings-creation-recursive-block
            v-for="(feature, index) in features"
            :id="feature.identifier"
            :key="`feature ${index}`"
            :feature="feature"
            :isOneRow="false"
            :children="feature.childFeatures"
            :display-child="!itemIdMove"
            :is-active="itemIdMove === feature.identifier"
            :is-drag-hover="itemIdHover === feature.identifier"
            :is-parent="true"
            draggable
            @edit="openModalEdit"
            @add="createNewNested"
            @dragstart.native="startDrag($event, index, feature.identifier)"
            @dragover.prevent.native="dragOver(feature.identifier)"
            @drop.prevent.native="dropItem($event, feature.identifier)"
            @dragend.native="dragEnd()"
          />
        </v-slide-y-reverse-transition>
        <div class="tw-text-center">
          <v-btn @click="createNew" class="tw-mt-6" outlined color="#449afd">
            <v-icon class="tw-mr-2">{{ icons.mdiPlus }}</v-icon>
            {{ $t('settings.creation.feature.create') }}
          </v-btn>
        </div>
      </div>
    </UiContainer>
    <settings-creation-modal
      :config="configModal"
      v-if="openModal === true"
      @close="openModal = false"
      @save="save"
      @update="update"
      @delete="deleteEmit"
    />
  </div>
</template>

<script>
import { mdiPlus } from '@mdi/js'
import UiContainer from '@/components/UI/Container.vue'
import UiTitle from '@/components/UI/Title.vue'
import SettingsCreationRecursiveBlock from '@/components/Settings/Creation/RecursiveBlock.vue'
import SettingsCreationModal from '@/components/Settings/Creation/Modal.vue'

const initDefaultModal = () => ({
  mode: 'create',
  type: 'featureParent',
  data: null,
})

export default {
  name: 'SettingsCreationFeature',
  props: {
    features: {
      type: Array,
      required: true,
    },
  },
  components: {
    UiContainer,
    UiTitle,
    SettingsCreationRecursiveBlock,
    SettingsCreationModal,
  },
  data() {
    return {
      icons: {
        mdiPlus,
      },
      openModal: false,
      configModal: {
        ...initDefaultModal(),
      },
      itemIdHover: null,
      itemIdMove: null,
    }
  },
  methods: {
    startDrag(evt, index, id) {
      this.itemIdMove = id
      evt.dataTransfer.dropEffect = 'move'
      evt.dataTransfer.effectAllowed = 'move'
      evt.dataTransfer.setData('itemIndex', index)
    },
    dragOver(id) {
      this.itemIdHover = id
    },
    dropItem(evt, dropId) {
      const itemDragIndex = evt.dataTransfer.getData('itemIndex')
      this.$store.commit('setting/MOVE_FEATURES', { itemDragIndex, dropId })
    },
    dragEnd() {
      this.$emit('savePosition')
      this.itemIdMove = null
      this.itemIdHover = null
    },
    createNew() {
      this.configModal = initDefaultModal()
      this.openModal = true
    },
    createNewNested(parentFeature) {
      this.configModal = {
        mode: 'create',
        type: 'feature',
        data: {
          parentFeature,
        },
      }
      this.openModal = true
    },
    openModalEdit(feature) {
      this.configModal = {
        mode: 'edition',
        type: 'default',
        data: feature,
      }
      this.openModal = true
    },
    save(feature) {
      this.$emit('save', feature)
      this.openModal = false
    },
    update(feature) {
      this.$emit('update', feature)
      this.openModal = false
    },
    deleteEmit(feature) {
      this.$emit('delete', feature)
      this.openModal = false
    },
  },
}
</script>

<style lang="scss" scoped>
.settings-creation-feature {
  @apply tw-h-full;
}
</style>
