<template>
  <v-fade-transition leave-absolute>
    <v-overlay :value="updating" v-if="updating" absolute>
      <v-progress-circular indeterminate size="64" width="6"></v-progress-circular>
    </v-overlay>
    <div v-else>
      <UiBanner fluid :title="$t('settings.creation.title')" :subtitle="$t('settings.creation.title')" />

      <v-tabs v-model="currentTab">
        <v-tab class="tw-normal-case" v-for="(tab, idx) in tabs" :key="idx" :href="`#${tab.key}`">
          {{ tab.label }}
        </v-tab>
      </v-tabs>
      <v-progress-linear :active="updatingCreation" height="5" color="#449afd" indeterminate />

      <settings-creation-vertical
        @save="vertical => updatingBeforeFunction(vertical, postVertical)"
        @update="vertical => updatingBeforeFunction(vertical, patchVertical)"
        @delete="vertical => updatingBeforeFunction(vertical, deleteVertical)"
        :verticals="verticals"
        v-if="currentTab === 'vertical'"
      />

      <settings-creation-type
        @save="type => updatingBeforeFunction(type, postType)"
        @update="type => updatingBeforeFunction(type, patchType)"
        @delete="type => updatingBeforeFunction(type, deleteType)"
        :types="types"
        v-if="currentTab === 'type'"
      />

      <settings-creation-feature
        @save="feature => updatingBeforeFunction(feature, postFeature)"
        @update="feature => updatingBeforeFunction(feature, patchFeature)"
        @delete="feature => updatingBeforeFunction(feature, deleteFeature)"
        @savePosition="updatingBeforeFunction(features, patchFeaturesPosition)"
        :features="features"
        v-if="currentTab === 'feature'"
      />

      <settings-creation-menus class="tw-relative" v-if="currentTab === 'menus'" />
    </div>
  </v-fade-transition>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import UiBanner from '@/components/UI/Banner.vue'
import SettingsCreationVertical from '@/components/Settings/Creation/Vertical.vue'
import SettingsCreationType from '@/components/Settings/Creation/Type.vue'
import SettingsCreationFeature from '@/components/Settings/Creation/Feature.vue'
import SettingsCreationMenus from '@/components/Settings/Creation/Menus.vue'

export default {
  name: 'SettingsCreation',
  components: {
    UiBanner,
    SettingsCreationVertical,
    SettingsCreationType,
    SettingsCreationFeature,
    SettingsCreationMenus,
  },
  data() {
    return {
      updatingCreation: false,
    }
  },
  async created() {
    this.setUpdating(true)
    await Promise.all([this.getVerticals(), this.getTypes(), this.getFeatures()])
    this.setUpdating(false)
  },
  computed: {
    ...mapState({
      types: state => state.setting.types,
      verticals: state => state.setting.verticals,
      features: state => state.setting.features,
      updating: state => state.backoffice.updating,
    }),
    currentTab: {
      get() {
        return this.$route.params.currentTab
      },
      set(tab) {
        return this.$router.replace({ ...this.$route, params: { ...this.$route.params, currentTab: tab } })
      },
    },
    tabs() {
      return [
        {
          label: this.$t('settings.creation.vertical.title'),
          key: 'vertical',
        },
        {
          label: this.$t('settings.creation.type.title'),
          key: 'type',
        },
        {
          label: this.$t('settings.creation.feature.title'),
          key: 'feature',
        },
        {
          label: this.$t('settings.creation.menus.title'),
          key: 'menus',
        },
      ]
    },
  },
  methods: {
    ...mapActions({
      getVerticals: 'setting/getVerticals',
      postVertical: 'setting/postVertical',
      patchVertical: 'setting/patchVertical',
      deleteVertical: 'setting/deleteVertical',

      getFeatures: 'setting/getFeatures',
      postFeature: 'setting/postFeature',
      patchFeature: 'setting/patchFeature',
      deleteFeature: 'setting/deleteFeature',
      patchFeaturesPosition: 'setting/patchFeaturesPosition',

      getTypes: 'setting/getTypes',
      postType: 'setting/postType',
      patchType: 'setting/patchType',
      deleteType: 'setting/deleteType',

      setUpdating: 'backoffice/setUpdating',
      setAlert: 'backoffice/setAlert',
    }),
    async updatingBeforeFunction(params, cb) {
      this.updatingCreation = true
      try {
        await cb(params)
        this.setAlert({
          color: 'success',
          text: this.$t('notification.update.setting'),
        })
      } catch {
        this.setAlert({
          color: 'error',
          text: this.$t('notification.error.default'),
        })
      }
      this.updatingCreation = false
    },
  },
}
</script>
